@import "styles/abstracts/_variables.scss";

.devicesContainer {
    display: flex;
    margin-top: 3rem;
    gap: 1rem;
    flex-wrap: wrap;

    .device {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        border: 0.1px solid #DCDCDC;
        border-radius: 5px;
        position: relative;

        &:hover {
            background-color: darken($color: #fdfdfd, $amount: 0.01);
        }

        .deviceInfo {
            padding: 2rem 4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .deviceName {
                font-size: 1.6rem;
                color: $black;
                margin-bottom: 1rem;
            }
    
            .status {
                text-align: center;
                padding: 0 1rem 0 0.4rem;
                color: #fff;
                border-radius: 15rem;
                margin-bottom: 0.8rem;
                font-size: 1rem;
                display: flex;
                align-items: center;

                span {
                    font-size: 1rem;
                    padding-top: 0.3rem;
                }
            }
    
            .info {
                color: #3E3E3E;
                margin-top: 0.3rem;
                font-size: 1.1rem;
            }
        }

        

        .bottom {
            display: flex;
            width: 100%;
            padding: 0 1rem;
            justify-content: space-between;
            font-size: 1.1rem;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background-color: rgb(250, 250, 250);

            

            .row {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                margin: 1rem 0;
                cursor: pointer;
                color: #3E3E3E;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

    }

    .addNew {
        justify-content: center;
        background-color: #F9F9F9;
        cursor: pointer;

        .addNewDevice {
            font-size: 1.6rem;
            color: #363636;
            font-weight: 500;
        }
    }
}