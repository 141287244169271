@tailwind base;
@tailwind components;
@tailwind utilities;


.ant-modal-body::-webkit-scrollbar {
  width: 10px;
}

.ant-modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ant-modal-body::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;  /* Scrollbar thumb color */
  border-radius: 10px;      /* Rounded scrollbar edges */
  border: 2px solid #f1f1f1; /* Padding around the thumb */
}

.ant-modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;         /* Darker on hover */
}

.form-scrollable-container {
  max-height: 60vh; /* Set a max height for form fields to allow scrolling */
  overflow-y: auto;
}

.sticky-container {
  position: relative;
}

.sticky {
  position: sticky;
  top: 0; /* Adjust this value to position it properly */
  z-index: 10; /* Ensure it's above other elements */
  background-color: white; /* Ensure it's not transparent when scrolling */
}