@import "styles/abstracts/_variables.scss";


.analyticsContainer {
    margin-bottom: 2rem;

    &__options {
        display: flex;
        gap: 1rem;
        margin-bottom: 2rem;

        p {
            padding: 0.5rem 1rem;
            border-radius: 20px;
            border: 0.1px solid $primary-color;
            font-size: 1.2rem;

            &:hover {
                background-color: $primary-color;
                color: #fff;
                cursor: pointer;
            }
        }

        p.active {
            background-color: $primary-color;
            color: #fff;
        }
    }

    &__line {
        width: 50%
    }
}

.reportsContainer {
    display: flex;
    gap: 1rem;

    &__filter {
        width: 25%;

        &__content {

            h3 {
                font-size: 1.4rem;
                font-weight: 600;
            }

            &__inputs {
                margin-top: 3rem;
                display: flex;
                flex-direction: column;
                gap: 2rem;

                .dates {
                    position: relative;
                    display: flex;
                    gap: 0.5;
                    background-color: #e7ffe7;
                    padding: 1rem;
                    flex: 1;
                    justify-content: space-between;
                    border-radius: 5px;
                    border: 0.01px solid #b0eeb0;
                    cursor: pointer;

                    &:hover {
                        background-color: darken($color: #e7ffe7, $amount: 2);
                    }

                    p {
                        color: $primary-color;
                    }

                    .date {
                        
                        p {
                            color: $primary-color;
                            font-weight: 500;
                            font-size: 1.4rem;
                        }
                    }
                }

                .dateRange {
                    position: absolute;
                    margin-top: 1rem;
                }
            }
        }
    }

    &__data {
        width: 75%;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            &__input {
                width: 30%;
            }
        }

        &__table {
            min-height: 62vh;
        }
    }
}